import React, { Fragment, ReactElement, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import styles from "./Invoice.module.scss";

import { dateFormat } from "../../utils/Utils";

import { IOutboundManifest } from "../../interfaces/outboundManifest.interface";
import { getOutboundManifestByIdAPI } from "../../api-services/outboundManifest.api";

const OutboundManifestInvoicePage = (): ReactElement => {
  const { id } = useParams();

  const [shipmentList, setShipmentList] = useState<any>();

  useEffect(() => {
    getOutboundManifestById();
  }, []);

  const getOutboundManifestById = (): void => {
    getOutboundManifestByIdAPI(id || "").then(
      (res: { payload: IOutboundManifest }) => {
        setShipmentList(res.payload);
      }
    );
  };

  const totalMftPcsForLocal = (): number =>
    shipmentList.Local.reduce(
      (acc: number, value: any) => acc + parseInt(value.mftPcs),
      0
    );

  const totalMftWtForLocal = (): number =>
    shipmentList.Local.reduce(
      (acc: number, value: any) => acc + parseFloat(value.mftWt),
      0
    );

  const totalChargeableWeightForLocal = (): number =>
    shipmentList.Local.reduce(
      (acc: number, value: any) => acc + parseFloat(value.chWt),
      0
    );
  const totalMftPcsForTansit = (): number =>
    shipmentList.Transit.reduce(
      (acc: number, value: any) => acc + parseInt(value.mftPcs),
      0
    );

  const totalMftWtForTransit = (): number =>
    shipmentList.Transit.reduce(
      (acc: number, value: any) => acc + parseFloat(value.mftWt),
      0
    );

  const totalChargeableWeightForTransit = (): number =>
    shipmentList.Transit.reduce(
      (acc: number, value: any) => acc + parseFloat(value.chWt),
      0
    );

  const totalPcs = ()  =>{
    const totalLocalPcs = shipmentList.Local.length > 0 ? totalMftPcsForLocal() : 0
    const totalTransitPcs = shipmentList.Transit.length > 0 ? totalMftPcsForTansit() : 0
    const result = totalLocalPcs + totalTransitPcs;

    return result;
  }

  const totalGrossWeight = ()  =>{
    const totalLocal = shipmentList.Local.length > 0 ? totalMftWtForLocal() : 0
    const totalTransit = shipmentList.Transit.length > 0 ? totalMftWtForTransit() : 0
    const result = totalLocal + totalTransit;

    return result.toFixed(2);
  }

  return (
    <Fragment>
      {!!shipmentList &&
        (shipmentList.Local.length > 0 || shipmentList.Transit.length > 0) && (
          <div className="content">
            <div className="card">
              <div className="row">
                <div className="col-12 text-center">
                  <p className={styles["header-text"]} style={{fontSize:'20px'}}>CARGO MANIFEST</p>
                  <p className={styles["sub-header-text"]}>(I.C.A.O ANNEX 9)</p>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-6">
                  <div className="row">
                    <div className="col-6 fw-bold">Flight No & Date:</div>
                    <div className="col-6">
                      {shipmentList.flightDetails.flightNo} / {" "}
                      {dateFormat(shipmentList.date)}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6 fw-bold">Point of Loading:</div>
                    <div className="col-6">{shipmentList.pointOfLoading}</div>
                  </div>

                  <div className="row">
                    <div className="col-6 fw-bold">Point of Unloading:</div>
                    <div className="col-6">{shipmentList.pointOfUploading}</div>
                  </div>

                  <div className="row">
                    <div className="col-6 fw-bold">Total Pcs:</div>
                    <div className="col-6">{totalPcs()}</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-6 fw-bold">Owner/Operator:</div>
                    <div className="col-6">
                      {shipmentList.flightDetails.airline}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6 fw-bold">Tail No:</div>
                    <div className="col-6">{shipmentList.tailNo}</div>
                  </div>

                  <div className="row">
                    <div className="col-6 fw-bold">Total AWB:</div>
                    <div className="col-6">{shipmentList.totalAwb}</div>
                  </div>

                  <div className="row">
                    <div className="col-6 fw-bold">Gross Weight:</div>
                    <div className="col-6">
                      {
                        //shipmentList.grossWt
                        totalGrossWeight()
                      }
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <p className={styles["header-text"]}>LOCAL</p>
                <div className="col-md-12 p-0">
                  <div
                    className={`${styles["invoice-table"]} table-responsive`}
                  >
                    <table className="w-100">
                      <thead>
                        <tr>
                          <th style={{fontWeight:'700'}}>CART/ULD</th>
                          <th className="fw-bold">AWB No</th>
                          <th className="fw-bold">ORG</th>
                          <th className="fw-bold">DEST</th>
                          <th className="fw-bold">SHC</th>
                          <th className="fw-bold">COMM DESC</th>
                          <th className="fw-bold">MFT PCS</th>
                          <th className="fw-bold">MFT WT</th>
                          <th className="fw-bold">CH WT</th>
                          <th className="fw-bold">REMARKS</th>
                        </tr>
                      </thead>
                      <tbody>
                        {shipmentList.Local.map(
                          (shipment: any, shipmentIdx: number) => (
                            <tr key={shipmentIdx}>
                              <td>{shipment.cart_uld}</td>
                              <td>{shipment.awbNo}</td>
                              <td>{shipment.org}</td>
                              <td>{shipment.dest}</td>
                              <td>{shipment.ssc}</td>
                              <td>{shipment.commDesc}</td>
                              <td>{shipment.mftPcs}</td>
                              <td>{shipment.mftWt}</td>
                              <td>{shipment.chWt}</td>
                              <td className={shipment.remark ? 'print-remark' :''}>{shipment.remark}</td>
                            </tr>
                          )
                        )}
                        <tr>
                          <td colSpan={5}>Summary:</td>
                          <td>Total:</td>
                          <td>{totalMftPcsForLocal()}</td>
                          <td>{totalMftWtForLocal().toFixed(2)}</td>
                          <td>{totalChargeableWeightForLocal().toFixed(2)}</td>
                          <td />
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {shipmentList.Transit.length > 0 && (
                <div className="row mt-5">
                  <p className={styles["header-text"]}>TRANSIT</p>
                  <div className="col-md-12 p-0">
                    <div
                      className={`${styles["invoice-table"]} table-responsive`}
                    >
                      <table className="w-100">
                        <thead>
                          <tr>
                            <th className="fw-bold">CART/ULD</th>
                            <th className="fw-bold">AWB No</th>
                            <th className="fw-bold">ORG</th>
                            <th className="fw-bold">DEST</th>
                            <th className="fw-bold">SHC</th>
                            <th className="fw-bold">COMM DESC</th>
                            <th className="fw-bold">MFT PCS</th>
                            <th className="fw-bold">MFT WT</th>
                            <th className="fw-bold">CH WT</th>
                            <th className="fw-bold">REMARKS</th>
                          </tr>
                        </thead>
                        <tbody>
                          {shipmentList.Transit.map(
                            (shipment: any, shipmentIdx: number) => (
                              <tr key={shipmentIdx}>
                                <td>{shipment.cart_uld}</td>
                                <td>{shipment.awbNo}</td>
                                <td>{shipment.org}</td>
                                <td>{shipment.dest}</td>
                                <td>{shipment.ssc}</td>
                                <td>{shipment.commDesc}</td>
                                <td>{shipment.mftPcs}</td>
                                <td>{shipment.mftWt}</td>
                                <td>{shipment.chWt}</td>
                                <td className={shipment.remark ? 'print-remark' :''}>{shipment.remark}</td>
                              </tr>
                            )
                          )}
                          <tr>
                            <td colSpan={5}>Summary:</td>
                            <td>Total:</td>
                            <td>{totalMftPcsForTansit()}</td>
                            <td>{totalMftWtForTransit().toFixed(2)}</td>
                            <td>{totalChargeableWeightForTransit().toFixed(2)}</td>
                            <td />
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
              <div className="row mt-5">
                <div className="col-12">Prepared by:</div>
                <div className="col-3">Staff No: {shipmentList.staffId}</div>
                <div className="col-5">
                  Staff Name: {shipmentList.staffName}
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-md-12 text-right">
                  <button
                    className="app-btn app-btn-primary"
                    onClick={() => window.print()}
                  >
                    Print
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
    </Fragment>
  );
};

export default OutboundManifestInvoicePage;
