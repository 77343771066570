import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';

import styles from './InboundAwb.module.scss';

import { useAlertStore, useUserProfileStore } from '../../store/store';

import { dateFormat } from '../../utils/Utils';

import { IForm, IFormErr, IInboundAwb, IInboundAwbPayload, ILocation } from '../../interfaces/inboundAwb.interface';
import { IBillingCode, IWarehouseLocation } from '../../interfaces/common.interface';
import { ISelectDropdown } from '../../interfaces/shipment.interface';
import { ICustomer } from '../../interfaces/customer.interface';
import {
  createInboundAirWayBillAPI, getInboundAwbByIdAPI,
  getInboundManifestByAwbNoAPI,
  updateInboundAirWayBillAPI
} from '../../api-services/inboundAWB.api';
import { getWarehouseLocationsAPI } from '../../api-services/location.api';
import { getHandlingCodesAPI } from '../../api-services/shipment.api';
import { getCustomersAPI } from '../../api-services/customer.api';

const EditInboundAwbPage = (): ReactElement => {
  const { id } = useParams();
  const navigate = useNavigate();

  const formInitial: IForm = {
    awbNo: '',
    flightList: [],
    agentCode: '',
    agentName: '',
    address: '',
    shipperName: [],
    consigneeName: [],
    consigneePhone: '',
    consigneeAddress: '',
    airWaybillNumber: '',
    airportOfDeparture: '',
    airportOfDestination: '',
    totalNoOfPackages: '',
    totalReceivedPackages: '',
    description: '',
    billingCode: [],
    specialHandlingCode: '',
    handlingInformation: '',
    grossWeight: '',
    volumeWeight: '',
    chargeableWeight: '',
    irregularityList: [],
    locationList: [
      {
        uuid: '',
        warehouse: 'Inbound',
        location: [],
        noOfPcs: '',
      },
    ],
    ratePerKg: '',
    // gst: '18',
    totalAmount: 0,
  };
  const formErrorInit: IFormErr = {
    awbNo: false,
    flightNo: false,
    destination: false,
    std: false,
    aircraftType: false,
    manifestedPcs: false,
    agentCode: false,
    agentName: false,
    address: false,
    shipperName: false,
    consigneeName: false,
    consigneePhone: false,
    consigneeAddress: false,
    airWaybillNumber: false,
    airportOfDeparture: false,
    airportOfDestination: false,
    totalNoOfPackages: false,
    description: false,
    billingCode: false,
    specialHandlingCode: false,
    handlingInformation: false,
    grossWeight: false,
    volumeWeight: false,
    chargeableWeight: false,
    locationList: [
      {
        warehouse: false,
        location: false,
        noOfPcs: false,
      },
    ],
    ratePerKg: false,
    // gst: false,
    totalAmount: false,
  };

  const [form, setForm] = useState<IForm>(formInitial);
  const [formError, setFormError] = useState<IFormErr>(formErrorInit);
  const [warehouseLocationList, setWarehouseLocationLIst] = useState<ISelectDropdown[]>([]);
  const [billingCodeList, setBillingCodeList] = useState<IBillingCode[]>([]);
  const [billingCodes, setBillingCodes] = useState<ISelectDropdown[]>([]);
  const [userList, setUserList] = useState<ICustomer[]>([]);
  const [customers, setCustomers] = useState<ISelectDropdown[]>([]);
  const [uuid, setUuid] = useState<string>(id || '');

  const userProfile = useUserProfileStore((state) => state.userProfile);
  const updateAlertText = useAlertStore(state => state.updateAlertText);

  useEffect(() => {
    getWarehouseLocations();
    getHandlingCodes();
    getCustomers();
  }, []);

  useEffect(() => {
    if (!!id) {
      getInboundAwbById();
    }
  }, []);

  const getCustomers = (): void => {
    getCustomersAPI()
      .then((res: {payload: ICustomer[]}) => {
        setUserList(res.payload);
        setCustomers(res.payload.map((customer) => ({ label: customer.name, value: customer.uuid })));
      });
  };

  const getInboundManifestByAwbNo = (): void => {
    const params = {
      awbNo: form.awbNo,
    };

    getInboundManifestByAwbNoAPI(params)
      .then((res: {payload: IInboundAwb[]}) => {
        if (!!res && !!res.payload) {
          formInit(res.payload);
        } else {
          updateAlertText('No data found for the entered awb no', 'danger');
        }
      });
  };

  const getInboundAwbById = (): void => {
    getInboundAwbByIdAPI(id || '')
      .then((res: {payload: any}) => {
        setForm({
          ...form,
          awbNo: res.payload.airWaybillNumber,
          flightList: res.payload.flightList,
          agentCode: res.payload.agentCode,
          agentName: res.payload.agentName,
          address: res.payload.address,
          shipperName: [{ label: res.payload.shipperDetails.name, value: res.payload.shipperDetails.uuid }],
          consigneeName: [{ label: res.payload.consigneeDetails.name, value: res.payload.consigneeDetails.uuid }],
          consigneePhone: res.payload.consigneeDetails.phone,
          consigneeAddress: res.payload.consigneeDetails.blockNo + ' ' + res.payload.consigneeDetails.locality + ' ' +
            res.payload.consigneeDetails.town + ' ' + res.payload.consigneeDetails.state + ' ' +
            res.payload.consigneeDetails.pinCode,
          airWaybillNumber: res.payload.airWaybillNumber,
          airportOfDeparture: res.payload.airportOfDeparture,
          airportOfDestination: res.payload.airportOfDestination,
          totalNoOfPackages: res.payload.totalNoOfPackages,
          totalReceivedPackages: res.payload.flightList.reduce((acc: any, value: any) =>
            acc + parseInt(value.receivedPcs), 0).toString(),
          description: res.payload.description,
          billingCode: [{ label: res.payload.billingCode, value: res.payload.billingCode }],
          specialHandlingCode: res.payload.specialHandlingCode,
          handlingInformation: res.payload.handlingInformation,
          grossWeight: res.payload.grossWeight,
          volumeWeight: res.payload.volumeWeight,
          chargeableWeight: res.payload.chargeableWeight,
          irregularityList: res.payload.irregularityList,
          locationList: res.payload.packageList.map((location: any) => ({
            uuid: location.uuid,
            warehouse: location.warehouse,
            location: [{ label: location.locationDetails.location, value: location.location }],
            noOfPcs: location.noOfPcs,
          })),
          ratePerKg: res.payload.ratePerKg,
          // gst: res.payload.gst,
          totalAmount: res.payload.totalAmount,
        });
        setFormError({
          ...formError,
          locationList: res.payload.packageList.map(() => ({
            warehouse: false,
            location: false,
            noOfPcs: false,
          })),
        });
      });
  };

  const getHandlingCodes = (): void => {
    const params = { type: 'INBOUND' };

    getHandlingCodesAPI(params)
      .then((res: {payload: IBillingCode[]}) => {
        setBillingCodeList(res.payload);
        setBillingCodes(res.payload.map((billingCode) => ({ label: billingCode.label, value: billingCode.value })));
      });
  };

  const getWarehouseLocations = (): void => {
    const params = {
      station: userProfile.station,
      wareHouseType: 'Inbound',
    };

    getWarehouseLocationsAPI(params)
      .then((res: {payload: IWarehouseLocation[]}) => {
        if (!!res && !!res.payload.length) {
          setWarehouseLocationLIst(res.payload.map(warehouse => ({
            label: warehouse.location,
            value: warehouse.id,
          })));
        } else {
          updateAlertText('No data found for the entered awb no', 'danger');
        }
      });
  };

  const formInit = (data: IInboundAwb[]): void => {
    setForm({
      ...form,
      flightList: data.map((inbound) => ({
        flightNo: inbound.InboundManiFest.flightDetails?.flightNo,
        date: inbound.InboundManiFest.date.split('T')[0],
        origin: inbound.org,
        destination: inbound.dest,
        std: inbound.InboundManiFest.flightDetails.arrivalTime,
        aircraftType: inbound.InboundManiFest.flightDetails.aircraftType,
        mftPcs: inbound.mftPcs,
        mftWt: inbound.mftWt,
        receivedPcs: inbound.receivedPcs,
      })),
      shipperName: [],
      consigneeName: [],
      consigneePhone: '',
      consigneeAddress: '',
      airWaybillNumber: data[0].awbNo,
      airportOfDeparture: data[0].org,
      airportOfDestination: data[0].dest,
      totalNoOfPackages: '',
      totalReceivedPackages: data.reduce((acc, value) => acc + parseInt(value.receivedPcs), 0).toString(),
      description: data[0].commDesc,
      billingCode: !!data[0].billingCode
        ? [{
          value: data[0].billingCode,
          label: billingCodes.find((item) => item.value === data[0].billingCode)?.label || '',
        }]
        : [],
      specialHandlingCode: data[0].ssc,
      grossWeight: data[0].mftWt,
      chargeableWeight: data[0].chWt,
      irregularityList: data
        .map(item => ({
          Irregularity: item.InboundManiFest.Irregularity.map(irregularity => ({
            ...irregularity,
            flightNo: item.InboundManiFest.flightDetails.flightNo,
            date: item.InboundManiFest.date,
          })),
        }))
        .flatMap(item => item.Irregularity.map(irregularity => ({
          flightNoDate: irregularity.flightNo + '/' + dateFormat(irregularity.date),
          irregularity: irregularity.irregularity,
          irregularityPcs: irregularity.irregularityPcs,
          remarks: irregularity.remarks,
        }))),
    });
  };

  const onUpdateForm = (value: any, type: string): void => {
    const updatedForm: any = { ...form };
    const updatedErrorForm: any = { ...formError };
    updatedForm[type] = value;

    if (type === 'airWaybillNumber' && value.length !== 8) {
      updatedErrorForm['airWaybillNumber'] = true;
      updatedForm['std'] = updatedForm.std;
    } else {
      updatedErrorForm['airWaybillNumber'] = false;
    }

    if (type === 'billingCode') {
      updatedForm['ratePerKg'] = billingCodeList.find(billingCode => billingCode.value === value[0].value)?.rate;
      updatedForm['totalAmount'] =
        parseFloat(billingCodeList.find(billingCode => billingCode.value === value[0].value)?.minimunPerAwb || '0');
    }

    if (type === 'consigneeName') {
      const consignee = userList.find(user => user.uuid === value[0].value);
      updatedForm['consigneePhone'] = consignee?.phone;
      updatedForm['consigneeAddress'] = consignee?.blockNo + ' ' + consignee?.locality + ' ' + consignee?.town + ' ' +
        consignee?.state + ' ' + consignee?.pinCode;
    }

    parseFloat(updatedForm['grossWeight']) >= parseFloat(updatedForm['volumeWeight'])
      ? updatedForm['chargeableWeight'] = updatedForm['grossWeight']
      : updatedForm['chargeableWeight'] = updatedForm['volumeWeight'];

    setForm(updatedForm);
    setFormError(updatedErrorForm);
  };

  const totalAmount = (): number => {
    const weight = parseFloat(form.chargeableWeight);
    const amount = (weight * parseFloat(form.ratePerKg));
    const totalAmount = amount > form.totalAmount ? amount : form.totalAmount;
    return Math.round(totalAmount);
  };

  const addItem = (): void => {
    const items = [...form.locationList];
    items.push(formInitial.locationList[0]);
    setForm({ ...form, locationList: items });

    const errItems = [...formError.locationList];
    errItems.push(formErrorInit.locationList[0]);
    setFormError({ ...formError, locationList: errItems });
  };

  const removeItem = (packageIdx: number): void => {
    const items = [...form.locationList];
    items.splice(packageIdx, 1);
    setForm({ ...form, locationList: items });

    const errItems = [...formError.locationList];
    errItems.splice(packageIdx, 1);
    setFormError({ ...formError, locationList: errItems });
  };

  const formValidity = (): boolean => {
    return (
      form.awbNo !== '' &&
      form.agentCode !== '' &&
      form.agentName !== '' &&
      form.address !== '' &&
      parseFloat(form.volumeWeight) > 0 &&
      parseFloat(form.chargeableWeight) > 0 &&
      form.locationList.reduce((acc, value) =>
        acc &&
        !!value.warehouse &&
        !!value.location &&
        parseInt(value.noOfPcs) > 0, true) &&
      parseFloat(form.ratePerKg) > 0
      // parseFloat(form.gst) > 0
    );
  };

  const onReset = (): void => {
    setForm(formInitial);
    setFormError(formErrorInit);
  };

  const onSave = (): void => {
    const payload: IInboundAwbPayload = {
      branch: userProfile.branch,
      flightList: form.flightList,
      agentCode: form.agentCode,
      agentName: form.agentName,
      address: form.address,
      shipperUuid: form.shipperName[0].value,
      consigneeUuid: form.consigneeName[0].value,
      airWaybillNumber: form.awbNo,
      airportOfDeparture: form.airportOfDeparture,
      airportOfDestination: form.airportOfDestination,
      totalNoOfPackages: form.totalNoOfPackages,
      totalReceivedPackages: form.totalReceivedPackages,
      description: form.description,
      billingCode: form.billingCode[0].value,
      specialHandlingCode: form.specialHandlingCode,
      handlingInformation: form.handlingInformation,
      grossWeight: form.grossWeight,
      volumeWeight: form.volumeWeight,
      chargeableWeight: form.chargeableWeight,
      irregularityList: form.irregularityList,
      locationList: form.locationList.map((item) => ({
        uuid: item.uuid,
        warehouse: item.warehouse,
        location: item.location[0].value,
        noOfPcs: item.noOfPcs,
      })),
      ratePerKg: form.ratePerKg,
      // gst: form.gst,
      totalAmount: totalAmount().toString(),
    };

    const cleanUUIDs = (items: ILocation[]) => items.map(item => {
      if (!item.uuid) delete item.uuid;
      return item;
    });

    payload.locationList = cleanUUIDs(payload.locationList);

    (!!id ? updateInboundAirWayBillAPI(id, payload) : createInboundAirWayBillAPI(payload))
      .then((res: {message: string, payload: {inboundAwbUuid: string}}) => {
        if (!!res) {
          updateAlertText(res.message, 'success');
          setUuid(res.payload.inboundAwbUuid);
        } else {
          updateAlertText('This airwaybill number is already exist', 'danger');
        }
      });
  };

  const resetHandler = (): void => {
    setForm(formInitial);
  }

  return (
    <div className="content">
      <div className="card px-5 py-3">
        <div className="card-title mb-3">Shipment Info</div>

        <div className="row">
          <div className="col-2 mb-2">
            <label
              id="air-waybill-number"
              className="form-label-text d-block mb-1"
            >
              Air Waybill Number <span className="text-danger">*</span>
            </label>
            <input
              id="air-waybill-number"
              type="text"
              placeholder="Enter air waybill number"
              className="form-input-field w-100"
              disabled={!!id}
              onChange={(e) => onUpdateForm(e.target.value, 'awbNo')}
              value={form.awbNo}
            />
            {/*{
              formError.awbNo && form.awbNo.length !== 8 && (
                <p className="form-label-text text-danger">
                  Air waybill number should be 8 digits
                </p>
              )
            }*/}
          </div>

          {
            !id &&
            <div className="col-8 mt-4">
              <button
                className="app-btn app-btn-primary-inverted me-2"
                onClick={() => resetHandler()}
              >
                Reset
              </button>
              <button
                className="app-btn app-btn-primary"
                onClick={() => getInboundManifestByAwbNo()}
              >
                Search
              </button>
            </div>
          }

        </div>

        <hr/>

        {
          form.flightList.length > 0 && (
            <Fragment>
              <div className="card-title mb-3">Flight Info</div>
              {
                form.flightList.map((flight, flightIdx: number) => (
                  <div className="row" key={flightIdx}>
                    <div className="col-md-2">
                      <label className="form-label-text d-block mb-1">Flight No & Date</label>
                      <p>{flight.flightNo}/{dateFormat(flight.date)}</p>
                    </div>
                    <div className="col-md-2">
                      <label className="form-label-text d-block mb-1">Origin</label>
                      <p>{flight.origin}</p>
                    </div>
                    <div className="col-md-2">
                      <label className="form-label-text d-block mb-1">STA</label>
                      <p>{flight.std}</p>
                    </div>
                    <div className="col-md-2">
                      <label className="form-label-text d-block mb-1">Mft Pcs</label>
                      <p>{flight.mftPcs}</p>
                    </div>
                    <div className="col-md-2">
                      <label className="form-label-text d-block mb-1">Mft Wt</label>
                      <p>{flight.mftWt}</p>
                    </div>
                    <div className="col-md-2">
                      <label className="form-label-text d-block mb-1">Received Pcs</label>
                      <p>{flight.receivedPcs}</p>
                    </div>
                  </div>
                ))
              }

              <hr/>

              <div className="card-title mb-3">Customer Info</div>

              <div className="row">
                <div className="col-3 mb-3">
                  <label
                    id="agent-code"
                    className="form-label-text d-block mb-1"
                  >
                    Agent Code <span className="text-danger">*</span>
                  </label>
                  <input
                    id="agent-code"
                    type="text"
                    placeholder="Enter agent code"
                    className="form-input-field w-100"
                    onChange={(e) => onUpdateForm(e.target.value, 'agentCode')}
                    value={form.agentCode}
                  />
                  {
                    formError.agentCode &&
                    <p className="form-label-text text-danger">Please enter agent code</p>
                  }
                </div>

                <div className="col-3 mb-3">
                  <label
                    id="agent-name"
                    className="form-label-text d-block mb-1"
                  >
                    Agent Name <span className="text-danger">*</span>
                  </label>
                  <input
                    id="agent-name"
                    type="text"
                    placeholder="Enter agent name"
                    className="form-input-field w-100"
                    onChange={(e) => onUpdateForm(e.target.value, 'agentName')}
                    value={form.agentName}
                  />
                  {
                    formError.agentName &&
                    <p className="form-label-text text-danger">Please enter agent name</p>
                  }
                </div>

                <div className="col-3 mb-3">
                  <label
                    id="agent-address"
                    className="form-label-text d-block mb-1"
                  >
                    Agent Address <span className="text-danger">*</span>
                  </label>
                  <input
                    id="agent-address"
                    type="text"
                    placeholder="Enter agent address"
                    className="form-input-field w-100"
                    onChange={(e) => onUpdateForm(e.target.value, 'address')}
                    value={form.address}
                  />
                  {
                    formError.address &&
                    <p className="form-label-text text-danger">Please enter address</p>
                  }
                </div>
              </div>

              <div className="row">
                <div className="col-3 mb-3">
                  <label
                    id="shipper-name"
                    className="form-label-text d-block mb-1"
                  >
                    Shipper Name <span className="text-danger">*</span>
                  </label>
                  <Select
                    id="shipper-name"
                    options={customers}
                    isSearchable
                    placeholder="Select Shipper Name"
                    onChange={(e) => onUpdateForm([e], 'shipperName')}
                    value={form.shipperName}
                  />
                  {formError.shipperName && (
                    <p className="form-label-text text-danger">
                      Please enter shipper name
                    </p>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-3 mb-3">
                  <label
                    id="consignee-name"
                    className="form-label-text d-block mb-1"
                  >
                    Consignee Name <span className="text-danger">*</span>
                  </label>
                  <Select
                    id="consignee-name"
                    options={customers}
                    isSearchable
                    placeholder="Select Consignee Name"
                    onChange={(e) => onUpdateForm([e], 'consigneeName')}
                    value={form.consigneeName}
                  />
                  {formError.consigneeName && (
                    <p className="form-label-text text-danger">
                      Please enter consignee name
                    </p>
                  )}
                </div>

                <div className="col-3 mb-3">
                  <label
                    id="consignee-phone"
                    className="form-label-text d-block mb-1"
                  >
                    Consignee Phone
                  </label>
                  <input
                    id="consignee-phone"
                    type="text"
                    placeholder="Enter consignee phone"
                    className="form-input-field w-100"
                    disabled={true}
                    onChange={() => null}
                    value={form.consigneePhone}
                  />
                </div>

                <div className="col-3 mb-3">
                  <label
                    id="consignee-address"
                    className="form-label-text d-block mb-1"
                  >
                    Consignee Address
                  </label>
                  <input
                    id="consignee-address"
                    type="text"
                    placeholder="Enter consignee address"
                    className="form-input-field w-100"
                    disabled={true}
                    onChange={() => null}
                    value={form.consigneeAddress}
                  />
                </div>
              </div>

              <hr/>

              <div className="card-title mb-3">Package Info</div>

              <div className="row">
                <div className="col-3 mb-3 position-relative">
                  <label
                    id="air-waybill-number"
                    className="form-label-text d-block mb-1"
                  >
                    Air Waybill Number <span className="text-danger">*</span>
                  </label>
                  <input
                    id="air-waybill-number"
                    type="text"
                    placeholder="Enter air waybill number"
                    className="form-input-field w-100"
                    disabled={true}
                    onChange={() => null}
                    value={form.airWaybillNumber}
                  />
                </div>

                <div className="col-3 mb-3">
                  <label
                    id="airport-of-departure"
                    className="form-label-text d-block mb-1"
                  >
                    Airport of Departure
                  </label>
                  <input
                    id="airport-of-departure"
                    type="text"
                    className="form-input-field w-100"
                    disabled={true}
                    onChange={() => null}
                    value={form.airportOfDeparture}
                  />
                </div>

                <div className="col-3 mb-3">
                  <label
                    id="airport-of-destination"
                    className="form-label-text d-block mb-1"
                  >
                    Airport of Destination
                  </label>
                  <input
                    id="airport-of-destination"
                    type="text"
                    className="form-input-field w-100"
                    disabled={true}
                    onChange={() => null}
                    value={form.airportOfDestination}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-3 mb-3">
                  <label
                    id="total-no-of-packages"
                    className="form-label-text d-block mb-1"
                  >
                    Total No Of Packages <span className="text-danger">*</span>
                  </label>
                  <input
                    id="total-no-of-packages"
                    type="number"
                    placeholder="Enter total no of packages"
                    className="form-input-field w-100"
                    onChange={(e) => onUpdateForm(e.target.value, 'totalNoOfPackages')}
                    value={form.totalNoOfPackages}
                  />
                </div>

                <div className="col-3 mb-3">
                  <label
                    id="total-no-of-packages"
                    className="form-label-text d-block mb-1"
                  >
                    Total Received Packages <span className="text-danger">*</span>
                  </label>
                  <input
                    id="total-no-of-packages"
                    type="number"
                    placeholder="Enter total no of packages"
                    className="form-input-field w-100"
                    disabled={true}
                    onChange={() => null}
                    value={form.totalReceivedPackages}
                  />
                </div>

                <div className="col-2 mb-3">
                  <label
                    id="description"
                    className="form-label-text d-block mb-1"
                  >
                    Description
                  </label>
                  <input
                    id="description"
                    type="text"
                    placeholder="Enter description"
                    className="form-input-field w-100"
                    disabled={true}
                    onChange={() => null}
                    value={form.description}
                  />
                </div>

                <div className="col-2 mb-3">
                  <label htmlFor="billing-code" className="form-label-text">
                    Billing Code <span className="text-danger">*</span>
                  </label>
                  <Select
                    id="billing-code"
                    options={billingCodes}
                    isSearchable
                    placeholder="Select billing code"
                    onChange={(e) => onUpdateForm([e], 'billingCode')}
                    value={form.billingCode}
                  />
                  {formError.billingCode && (
                    <p className="form-label-text text-danger">
                      Please select billing code
                    </p>
                  )}
                </div>

                <div className="col-2 mb-3">
                  <label id="special-handling-code" className="form-label-text">
                    Special Handling Code <span className="text-danger">*</span>
                  </label>
                  <input
                    id="special-handling-code"
                    type="text"
                    placeholder="Enter special handling code"
                    className="form-input-field w-100"
                    disabled={true}
                    onChange={() => null}
                    value={form.specialHandlingCode}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-6 mb-3">
                  <label
                    id="handling-information"
                    className="form-label-text d-block mb-1"
                  >
                    Handling Information
                  </label>
                  <input
                    id="handling-information"
                    type="text"
                    placeholder="Enter handling information"
                    className="form-input-field w-100"
                    onChange={(e) => onUpdateForm(e.target.value, 'handlingInformation')}
                    value={form.handlingInformation}
                  />
                </div>

                <div className="col-3 mb-3">
                  <label
                    id="gross-weight"
                    className="form-label-text d-block mb-1"
                  >
                    Gross Weight <span className="text-danger">*</span>
                  </label>
                  <input
                    id="gross-weight"
                    type="number"
                    placeholder="Enter gross weight"
                    className="form-input-field w-100"
                    onChange={(e) => onUpdateForm(e.target.value, 'grossWeight')}
                    value={form.grossWeight}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-3 mb-3">
                  <label
                    id="volume-weight"
                    className="form-label-text d-block mb-1"
                  >
                    Volume Weight <span className="text-danger">*</span>
                  </label>
                  <input
                    id="volume-weight"
                    type="number"
                    placeholder="Enter volume weight"
                    className="form-input-field w-100"
                    onChange={(e) => onUpdateForm(e.target.value, 'volumeWeight')}
                    value={form.volumeWeight}
                  />
                </div>

                <div className="col-3 mb-3">
                  <label
                    id="chargeable-weight"
                    className="form-label-text d-block mb-1"
                  >
                    Chargeable Weight <span className="text-danger">*</span>
                  </label>
                  <input
                    id="chargeable-weight"
                    type="number"
                    placeholder="Enter chargeable weight"
                    disabled={true}
                    className="form-input-field w-100"
                    onChange={() => null}
                    value={form.chargeableWeight}
                  />
                </div>
              </div>

              {
                form.irregularityList.length > 0 &&
                <Fragment>
                  <div className="card-title mb-3">Irregularity Info</div>

                  {
                    form.irregularityList.map((item, itemIdx: number) => (
                      <div key={itemIdx} className="row">
                        <div className="col-3 mb-3">
                          <label
                            htmlFor={`flight-no-date-${itemIdx}`}
                            className="form-label-text"
                          >
                            Flight No & Date
                          </label>
                          <input
                            id={`flight-no-date-${itemIdx}`}
                            type="text"
                            placeholder="Enter flight no & date"
                            className="form-input-field w-100"
                            disabled={true}
                            onChange={() => null}
                            value={item.flightNoDate}
                          />
                        </div>

                        <div className="col-3 mb-3">
                          <label
                            htmlFor={`irregularity-${itemIdx}`}
                            className="form-label-text"
                          >
                            Irregularity
                          </label>
                          <input
                            id={`irregularity-${itemIdx}`}
                            type="text"
                            placeholder="Enter irregularity"
                            className="form-input-field w-100"
                            disabled={true}
                            onChange={() => null}
                            value={item.irregularity}
                          />
                        </div>

                        <div className="col-3 mb-3">
                          <label
                            htmlFor={`irregularity-pcs-${itemIdx}`}
                            className="form-label-text"
                          >
                            Irregularity Pcs
                          </label>
                          <input
                            id={`irregularity-pcs-${itemIdx}`}
                            type="number"
                            placeholder="Enter irregularity pcs"
                            className="form-input-field w-100"
                            disabled={true}
                            onChange={() => null}
                            value={item.irregularityPcs}
                          />
                        </div>

                        <div className="col-3 mb-3">
                          <label
                            htmlFor={`remarks-${itemIdx}`}
                            className="form-label-text"
                          >
                            Remarks
                          </label>
                          <input
                            id={`remarks-${itemIdx}`}
                            type="text"
                            placeholder="Enter remarks"
                            className="form-input-field w-100"
                            disabled={true}
                            onChange={() => null}
                            value={item.remarks}
                          />
                        </div>
                      </div>
                    ))
                  }
                </Fragment>
              }

              <div className="card-title mb-3">Location Info</div>

              {
                form.locationList.map((item, itemIdx: number) => (
                  <div
                    key={itemIdx}
                    className={`row mb-3 ${styles['package-list-container']}`}
                  >
                    <div className="col-11">
                      <div className="row">
                        <div className="col-3 mb-3">
                          <label
                            htmlFor={`warehouse-${itemIdx}`}
                            className="form-label-text"
                          >
                            Warehouse <span className="text-danger">*</span>
                          </label>
                          <input
                            id={`warehouse-${itemIdx}`}
                            type="text"
                            placeholder="Enter warehouse"
                            className="form-input-field w-100"
                            disabled={true}
                            onChange={() => null}
                            value={item.warehouse}
                          />
                        </div>

                        <div className="col-3 mb-3">
                          <label
                            htmlFor={`location-${itemIdx}`}
                            className="form-label-text"
                          >
                            Location <span className="text-danger">*</span>
                          </label>
                          <Select
                            id={`location-${itemIdx}`}
                            options={warehouseLocationList}
                            isSearchable
                            placeholder="Select location"
                            onChange={(e: any) => {
                              form.locationList[itemIdx].location = [e];
                              setForm({ ...form, locationList: form.locationList });
                            }}
                            value={form.locationList[itemIdx].location}
                          />
                          {
                            formError.locationList[itemIdx].location &&
                            <p className="form-label-text text-danger">
                              Please select location
                            </p>
                          }
                        </div>

                        <div className="col-3 mb-3">
                          <label
                            htmlFor={`no-of-pcs-${itemIdx}`}
                            className="form-label-text"
                          >
                            No of Pcs <span className="text-danger">*</span>
                          </label>
                          <input
                            id={`no-of-pcs-${itemIdx}`}
                            type="number"
                            placeholder="Enter no of pcs"
                            className="form-input-field w-100"
                            onChange={(e) => {
                              const value = e.target.value;
                              form.locationList[itemIdx].noOfPcs = value;
                              setForm({ ...form, locationList: form.locationList });
                            }}
                            value={item.noOfPcs}
                          />
                          {
                            formError.locationList[itemIdx].noOfPcs &&
                            <p className="form-label-text text-danger">
                              Please enter no of pcs, shouldn't less than or equal to 0
                            </p>
                          }
                        </div>
                      </div>
                    </div>

                    <div className="col-1 content-center">
                      {(form.locationList.length === 1 ||
                        form.locationList.length - 1 === itemIdx) && (
                        <i
                          className={`fa fa-plus text-primary cursor-pointer`}
                          onClick={() => addItem()}
                        />
                      )}
                      {form.locationList.length !== 1 && (
                        <i
                          className="fa fa-trash text-danger cursor-pointer ms-3"
                          onClick={() => removeItem(itemIdx)}
                        />
                      )}
                    </div>
                  </div>
                ))
              }

              <hr/>

              <div className="card-title mb-3">Charges Info</div>

              <div className="row">
                <div className="col-3 mb-3">
                  <label
                    htmlFor="rate-per-kg"
                    className="form-label-text d-block mb-1"
                  >
                    Rate per KG <span className="text-danger">*</span>
                  </label>
                  <input
                    id="rate-per-kg"
                    type="number"
                    placeholder="Enter rate per kg"
                    className="form-input-field w-100"
                    disabled={true}
                    onChange={(e) => onUpdateForm(e.target.value, 'ratePerKg')}
                    value={form.ratePerKg}
                  />
                </div>

                {/*<div className="col-3 mb-3">
                  <label
                    htmlFor="gst"
                    className="form-label-text d-block mb-1"
                  >
                    GST <span className="text-danger">*</span>
                  </label>
                  <input
                    id="gst"
                    type="number"
                    placeholder="Enter gst"
                    className="form-input-field w-100"
                    disabled={true}
                    onChange={(e) => onUpdateForm(e.target.value, 'gst')}
                    value={form.gst}
                  />
                </div>*/}

                <div className="col-3 mb-3">
                  <label
                    htmlFor="total-amount"
                    className="form-label-text d-block mb-1"
                  >
                    Total Amount <span className="text-danger">*</span>
                  </label>
                  <input
                    id="total-amount"
                    type="number"
                    placeholder="Enter total amount"
                    className="form-input-field w-100"
                    disabled={true}
                    onChange={() => null}
                    value={totalAmount()}
                  />
                </div>
              </div>

              <div className="text-right mt-4">
                <button
                  className="app-btn app-btn-primary-inverted me-2"
                  onClick={() => onReset()}
                >
                  Reset
                </button>
                <button
                  className="app-btn app-btn-primary me-2"
                  disabled={!formValidity()}
                  onClick={() => onSave()}
                >
                  Save
                </button>
                <button
                  className="app-btn app-btn-primary"
                  disabled={!uuid}
                  onClick={() => navigate(`/inbound-awb-invoice/${uuid}`)}
                >
                  Invoice
                </button>
              </div>
            </Fragment>
          )
        }
      </div>
    </div>
  );
};

export default EditInboundAwbPage;
