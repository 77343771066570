import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { dateFormat, dateTimeFormat } from '../../utils/Utils';

import { IShipment } from '../../interfaces/shipment.interface';
import { deleteShipmentAPI, getShipmentsAPI } from '../../api-services/shipment.api';
import { useAlertStore } from '../../store/store';

const OutboundAwbPage = (): ReactElement => {
  const navigate = useNavigate();

  const [shipmentList, setShipmentList] = useState<IShipment[]>([]);
  const [searchKey, setSearchKey] = useState<string>('');
  const updateAlertText = useAlertStore(state => state.updateAlertText);


  useEffect(() => {
    getShipments();
  }, []);

  const getShipments = (): void => {
    getShipmentsAPI().then((res: {payload: IShipment[]}) => {
      if (res && searchKey) {
        const filteredData = res.payload.filter((data) => data.airWaybillNumber.includes(searchKey));
        setShipmentList(filteredData);
      } else {
        setShipmentList(res.payload);
      }
    });
  };

  const deleteShipment = (uuid: string) => {
    deleteShipmentAPI(uuid)
      .then((res) => {
        if (res) {
          updateAlertText('Shipment deleted successfully', 'success');
          getShipments();
        }
      });
  }

  return (
    <div className="content">
      <div className="card">
        <div className="row">
          <div className="col-md-4">
            <div className="input-group flex-nowrap">
              <input
                type="text"
                className="form-control"
                placeholder="Search Awb Number"
                aria-label="awbNumber"
                aria-describedby="addon-wrapping"
                value={searchKey}
                onChange={(e) => setSearchKey(e.target.value)}
              />
              <span
                className="input-group-text"
                id="addon-wrapping"
                onClick={getShipments}
              >
                <i className="fa fa-search cursor-pointer"/>
              </span>
            </div>
          </div>
          <div className="col-md-8 text-right">
            <button
              className="app-btn app-btn-primary"
              onClick={() => navigate('/add-outbound-awb')}
            >
              Add Outbound AWB
            </button>
          </div>

          <div className="col-md-12">
            <div className="table-responsive mt-3">
              <table className="mb-0 w-100">
                <thead>
                <tr>
                  <th>Sl. No</th>
                  <th>AWB No</th>
                  <th>Owner/Operator</th>
                  <th>Agent Name</th>
                  <th>Origin</th>
                  <th>Destination</th>
                  <th>Total no of Pcs</th>
                  <th>Gross Weight</th>
                  <th>Chargeable Weight</th>
                  <th>Flight No & Date</th>
                  <th>SHC</th>
                  <th>Billing Code</th>
                  <th>Rate per KG</th>
                  <th>Amount to be collected</th>
                  <th>Created Date</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {shipmentList.map((shipment, shipmentIdx: number) => (
                  <tr key={shipmentIdx}>
                    <td>{shipmentIdx + 1}</td>
                    <td>{shipment.airWaybillNumber}</td>
                    <td>{shipment.flightList.length > 0 ? shipment.flightList[0].flightDetails?.airline : ''}</td>
                    <td>{shipment.agentCode}</td>
                    <td>{shipment.airportOfDeparture}</td>
                    <td>{shipment.airportOfDestination}</td>
                    <td>{shipment.totalNoOfPackages}</td>
                    <td>{shipment.grossWeight}</td>
                    <td>{shipment.chargeableWeight}</td>
                    <td>{shipment.flightList.map((flight, flightIdx: number) => (
                      <span key={flightIdx}
                            className="d-block">{flight.flightDetails?.flightNo} / {dateFormat(flight.shippingDate)}</span>
                    ))}</td>
                    <td>{shipment.shc}</td>
                    <td>{shipment.billingCode}</td>
                    <td> {shipment.ratePerKg}</td>
                    <td> {shipment.amountToBeCollected}</td>
                    <td>{dateTimeFormat(shipment.updatedAt)}</td>
                    <td className="text-center">
                      <i
                        className="fa fa-pencil-alt text-danger cursor-pointer me-3"
                        onClick={() => navigate(`/update-shipment/${shipment.uuid}`)}
                      />
                      <i
                        className="fa fa-file-invoice text-primary cursor-pointer me-3"
                        onClick={() => navigate(`/invoice/${shipment.uuid}`)}
                      />
                      <i
                        className="fa fa-trash text-danger cursor-pointer"
                        onClick={() => deleteShipment(shipment.uuid)}
                      />
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutboundAwbPage;
