import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import styles from './Invoice.module.scss';

import Logo from '../../assets/images/logo/omega-logo.png';

import { getOutboundCustomerInvoiceByIdAPI } from '../../api-services/outboundCustomerInvoice';
import { IOutboundCustomerInvoice } from '../../interfaces/outboundCustomerInvoice.interface';
import { dateFormat } from '../../utils/Utils';

const OutboundTaxInvoice = (): ReactElement => {
  const { id } = useParams();

  const [dataSource, setDataSource] = useState<IOutboundCustomerInvoice>();

  useEffect(() => {
    getOutboundCustomerInvoiceById();
  }, []);

  const getOutboundCustomerInvoiceById = (): void => {
    getOutboundCustomerInvoiceByIdAPI(id || '')
      .then((res: {payload: IOutboundCustomerInvoice}) => {
        setDataSource(res.payload);
      });
  };

  const getTotalValue = (field: string): number => {
    return dataSource?.serviceProvidedList.reduce((acc, value: any) => {
      acc += parseFloat(value[field]);
      return acc;
    }, 0) || 0;
  };

  const getTotalAmt = (dataIdx: number): number => {
    return (
      parseFloat(dataSource?.serviceProvidedList[dataIdx].amount || '0') +
      parseFloat(dataSource?.serviceProvidedList[dataIdx].igstAmt || '0') +
      parseFloat(dataSource?.serviceProvidedList[dataIdx].cgstAmt || '0') +
      parseFloat(dataSource?.serviceProvidedList[dataIdx].sgstAmt || '0')
    );
  };

  return (
    <div className="content">
      <div className="card">

        <div className="row">
          <div className="col-3 content-center">
            <img src={Logo} alt="logo" height="50"/>
          </div>

          <div className="col-6 text-center">
            <p className={styles['header-text']}>OMEGA ENTERPRISES</p>
            <p className={styles['sub-header-text']}>Domestic Cargo Terminal - Regulated Agent</p>
            <p className={styles['sub-header-text']}>Visakhapatnam International Airport</p>
            <p className={styles['sub-header-text']}>Andhra Pradesh, India - 530009</p>
            <p className={styles['sub-header-text']}>Ph No : 0891 2940603</p>
            <p className={styles['sub-header-text']}>GST No: 37AFCPR4510F1ZO</p>
          </div>
        </div>

        <div className="row border">
          <div className="col-12">
            <p className={`${styles['body-header-text']} text-center`}>TAX INVOICE - OUTBOUND SHIPMENT</p>
          </div>
        </div>

        <div className="row border border-top-0">
          <div className="col-6 border-right">

            <div className="row">
              <div className="col-6 d-flex justify-content-between">
                <p className={styles['body-header-text']}>AWB No</p>
                <p className={styles['body-header-text']}>:</p>
              </div>
              <div className="col-6 d-flex justify-content-between">
                <p className={styles['body-header-text']}>{dataSource?.awbNo}</p>
              </div>

              <div className="col-6 d-flex justify-content-between">
                <p className={styles['body-header-text']}>Total No Of Pcs</p>
                <p className={styles['body-header-text']}>:</p>
              </div>
              <div className="col-6 d-flex justify-content-between">
                <p className={styles['body-header-text']}>{dataSource?.totalNoOfPackages}</p>
              </div>

              <div className="col-6 d-flex justify-content-between">
                <p className={styles['body-header-text']}>Origin</p>
                <p className={styles['body-header-text']}>:</p>
              </div>
              <div className="col-6 d-flex justify-content-between">
                <p className={styles['body-header-text']}>{dataSource?.flightDetails?.station === "Visakhapatnam" ? 'VTZ' : 'VGA'}</p>
              </div>

              <div className="col-6 d-flex justify-content-between">
                <p className={styles['body-header-text']}>Destination</p>
                <p className={styles['body-header-text']}>:</p>
              </div>
              <div className="col-6 d-flex justify-content-between">
                <p className={styles['body-header-text']}>{dataSource?.flightDetails?.airportCode}</p>
              </div>
            </div>

          </div>
          <div className="col-6">

            <div className="row">
              <div className="col-6 d-flex justify-content-between">
                <p className={styles['body-header-text']}>Description</p>
                <p className={styles['body-header-text']}>:</p>
              </div>
              <div className="col-6 d-flex justify-content-between">
                <p className={styles['body-header-text']}>{dataSource?.description}</p>
              </div>

              <div className="col-6 d-flex justify-content-between">
                <p className={styles['body-header-text']}>Gross Weight</p>
                <p className={styles['body-header-text']}>:</p>
              </div>
              <div className="col-6 d-flex justify-content-between">
                <p className={styles['body-header-text']}>{dataSource?.grossWeight}</p>
              </div>

              <div className="col-6 d-flex justify-content-between">
                <p className={styles['body-header-text']}>Chargeable Weight</p>
                <p className={styles['body-header-text']}>:</p>
              </div>
              <div className="col-6 d-flex justify-content-between">
                <p className={styles['body-header-text']}>{dataSource?.chargeableWeight}</p>
              </div>

              <div className="col-6 d-flex justify-content-between">
                <p className={styles['body-header-text']}>Flight No & Date</p>
                <p className={styles['body-header-text']}>:</p>
              </div>
              <div className="col-6 d-flex justify-content-between">
                <p className={styles['body-header-text']}>
                  {dataSource?.flightDetails?.flightNo} & {dateFormat(dataSource?.date || '')}
                </p>
              </div>
            </div>

          </div>
        </div>

        <div className="row border border-top-0">
          <div className="col-6">
            <p className={`${styles['body-header-text']} text-center`}>Details of Agent</p>
          </div>

          <div className="col-6">
            <p className={`${styles['body-header-text']} text-center`}>Details of Shipper</p>
          </div>
        </div>

        <div className="row border border-top-0">
          <div className="col-6 border-right">

            <div className="row">
              <div className="col-6 d-flex justify-content-between">
                <p className={styles['body-header-text']}>Customer Name</p>
                <p className={styles['body-header-text']}>:</p>
              </div>
              <div className="col-6 d-flex justify-content-between">
                <p className={styles['body-header-text']}>{dataSource?.shipperDetails?.name}</p>
              </div>

              <div className="col-6 d-flex justify-content-between">
                <p className={styles['body-header-text']}>Agency Code</p>
                <p className={styles['body-header-text']}>:</p>
              </div>
              <div className="col-6 d-flex justify-content-between">
                <p className={styles['body-header-text']}>{dataSource?.agentCode}</p>
              </div>

              <div className="col-6 d-flex justify-content-between">
                <p className={styles['body-header-text']}>Name of Agency</p>
                <p className={styles['body-header-text']}>:</p>
              </div>
              <div className="col-6 d-flex justify-content-between">
                <p className={styles['body-header-text']}>{dataSource?.agentName}</p>
              </div>

              <div className="col-6 d-flex justify-content-between">
                <p className={styles['body-header-text']}>GST No</p>
                <p className={styles['body-header-text']}>:</p>
              </div>
              <div className="col-6 d-flex justify-content-between">
                <p className={styles['body-header-text']}>{dataSource?.gstNo}</p>
              </div>

              <div className="col-6 d-flex justify-content-between">
                <p className={styles['body-header-text']}>PAN No</p>
                <p className={styles['body-header-text']}>:</p>
              </div>
              <div className="col-6 d-flex justify-content-between">
                <p className={styles['body-header-text']}>{dataSource?.panNo}</p>
              </div>
            </div>

          </div>
          <div className="col-6">

            <div className="row">
              <div className="col-6 d-flex justify-content-between">
                <p className={styles['body-header-text']}>Shipper</p>
                <p className={styles['body-header-text']}>:</p>
              </div>
              <div className="col-6 d-flex justify-content-between">
                <p className={styles['body-header-text']}>{dataSource?.shipperDetails?.name}</p>
              </div>

              <div className="col-6 d-flex justify-content-between">
                <p className={styles['body-header-text']}>Address</p>
                <p className={styles['body-header-text']}>:</p>
              </div>
              <div className="col-6 d-flex justify-content-between">
                <p className={styles['body-header-text']}>{dataSource?.shipperDetails?.locality}</p>
              </div>
            </div>

          </div>
        </div>

        <div className="row">
          <div className="col-12 p-0">

            <div className={`${styles['invoice-table']} table-responsive`}>
              <table className="w-100">
                <thead>
                <tr>
                  <th rowSpan={2}>Sl.No</th>
                  <th rowSpan={2}>Charge Description</th>
                  <th rowSpan={2}>HSN code</th>
                  <th rowSpan={2}>Rate</th>
                  <th rowSpan={2}>Quantity</th>
                  <th rowSpan={2}>Amount</th>
                  <th colSpan={2}>IGST</th>
                  <th colSpan={2}>CGST</th>
                  <th colSpan={2}>SGST</th>
                  <th rowSpan={2}>Total</th>
                </tr>
                <tr>
                  <th>Rate</th>
                  <th>Amount</th>
                  <th>Rate</th>
                  <th>Amount</th>
                  <th>Rate</th>
                  <th>Amount</th>
                </tr>
                </thead>
                <tbody>
                {
                  dataSource?.serviceProvidedList.map((data, dataIdx) => (
                    <tr key={dataIdx}>
                      <td>{dataIdx + 1}</td>
                      <td>{data.item}</td>
                      <td>{data.hsnCode}</td>
                      <td>{data.rate}</td>
                      <td>{data.quantity}</td>
                      <td>₹{data.amount}</td>
                      <td>{data?.igstPct}%</td>
                      <td>₹{data?.igstAmt}</td>
                      <td>{data?.cgstPct}%</td>
                      <td>₹{data?.cgstAmt}</td>
                      <td>{data?.sgstPct}%</td>
                      <td>₹{data?.sgstAmt}</td>
                      <td>₹{getTotalAmt(dataIdx).toFixed(2)}</td>
                    </tr>
                  ))
                }
                </tbody>
                <tfoot>
                <tr>
                  <td colSpan={12} className="text-right">Total amount to be collected:</td>
                  <td>₹{dataSource?.netAmount}</td>
                </tr>
                </tfoot>
              </table>
            </div>

          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12">Prepared by:</div>
          <div className="col-3">Staff No: {dataSource?.staffId}</div>
          <div className="col-5">Staff Name: {dataSource?.staffName}</div>
        </div>

        <div className="row mt-4">
          <div className="col-md-12 text-right">
            <button
              className="app-btn app-btn-primary"
              onClick={() => window.print()}
            >
              Print
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutboundTaxInvoice;
